// ----- parts
@import "../parts/mixins.less";
@import "../parts/helpers.less";
@import "../parts/blocks.less";
@import "../parts/elements.less";
@import "../parts/forms.less";
@import "../parts/menu.less";
@import "../parts/banners.less";
@import "../parts/gallery.less";
@import "../parts/partners.less";

// -- main variables
@main_font_weight: normal;
@main_font_family: 'Roboto Condensed', sans-serif;
@header_font_family: 'Oswald', sans-serif;
@main_font_size: 16px;
@main_font_weight: 300;
@main_line_height: 30px;
@main_text_color: #0a0a0a;
@main_bg: #ffffff;
@main_color: #1b1b1b;
@main_color2: #933956;
@main_border_color: #d8d8d8;
@main_border: 1px solid @main_border_color;
@text_on_main_color: #ffffff;
@link_color: @main_color2;
@link_text_decoration: none;
@link_hover_color: darken(@main_color2, 20%);
@link_hover_text_decoration: none;
@error_color: #F61237;
@container_padding: 0 80px;

// =main
//============================================================================*/
body, html {
    height: 100%;
}
body {
    font-weight: @main_font_weight;
    font-family: @main_font_family;
    font-size: @main_font_size;
    line-height: @main_line_height;
    font-weight: @main_font_weight;
    color: @main_text_color;
    background: @main_bg;
    text-decoration: none; 
    padding: 0;
    margin: 0 auto;
}
a {
    color: @link_color;
    text-decoration: @link_text_decoration;
    .transition();
    outline: none;

    &:hover, &:focus, &:active {
        color: @link_hover_color;
        text-decoration: @link_hover_text_decoration;
        outline: none;
    }
}
button {
    border: none;
    outline: none;
}
.content {
    margin: 80px 0 80px 0;
}
.content-contact {
    margin: 80px 0 0 0;
}
label.error {
    display: none;
    color: @error_color;
    font-weight: 300;
}
.my-container {
    padding: @container_padding;
}

// =header
//============================================================================*/
header#header {
    height: 100%;

    #top {
        padding: 0 85px;
    }
    .container {
        position: relative;
    }
    .search-box {
        float: left;
        margin: 23px 10px 0 20px;
        position: relative;
        z-index: 50;

        button {
            background: none;
            color: @text_on_main_color;
            margin: 0 5px 0 0;
        }
        input {
            background: none;
            border: none;
            outline: none;
            color: @text_on_main_color;
        }
    }
    .social {
        float: right;
        color: @text_on_main_color;
        font-size: 30px;
        font-weight: 400;
        line-height: 40px;
        margin: 22px 0 0 0;

        a {
            color: @text_on_main_color;
            margin: 0 8px;

            &:hover, &:focus, &:active {
                color: @main_color2;
            }
        }
    }
    &.subpage {
        height: 480px;
    }
}
.logo {
    z-index: 9999;
    position: absolute;
    top: 20px;
    left: 0;

    h1 {
        margin: 0;
    }
    a {
        display: block;
        background: rgba(0, 0, 0, 0.77);
        padding: 25px 35px;
    }
    &.subpage {
        display: none;
    }
}
.flags {
    float: right;
    margin: 10px 15px 0 0;

    a {
        display: inline-block;
        margin: 0 0 0 5px;
    }
}

// =archive-box
//============================================================================*/
@archive_box_border: 1px solid #E8E8E8;
@archive_box_year_font_size: 25px;

.archive-box {

    .archive-item {
        padding: 5px 0 5px 15px;
        border-left: @archive_box_border;
        margin: 0 0 0 25px;
    }
    .year {
        font-size: @archive_box_year_font_size;
        margin: 10px 0 10px 0;

        &:first-child {
            margin: 0 0 10px 0;
        }
    }
}

// =members-home
//============================================================================*/
#members-home {
    padding: 100px 0 0 0;
}
#members-home-slider, #members-page-news { 
    margin: 0 -5px;

    .item {
        cursor:pointer;
        .news-block-photo {
            max-height: 600px;
            overflow: hidden;
            margin: 0 5px;
            position: relative;
            z-index: 10;

            img {
                width: 100%;
                margin: 0 auto;
            }
        }
        .hover-bg {

            .inside {
                .center-absolute();
                margin-top: -30px;
                text-align: center;

                .head-news-class {
                    color: @text_on_main_color;
                    padding: 0;
                    margin: 0 0 30px 0;
                    position: relative;
                    bottom: -50px;
                    .transition();

                    a {
                        color: @text_on_main_color;
                    }
                }
                .text-small {
                    color: darken(@text_on_main_color, 40%);
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 20px;
                    position: relative;
                    bottom: -50px;
                    .transition();

                    p {
                        margin: 0;
                    }
                }
            }
            .number {
                position: absolute;
                width: 100%;
                text-align: center;
                bottom: 20px;
                .transition();
                -webkit-transition-delay: 0.2s;
                transition-delay: 0.2s;
                opacity: 0;
            }
        }
        &:hover {
            .hover-bg {
                opacity: 1;

                .inside {
                    .head-news-class {
                        bottom: 0;
                    }
                    .text-small {
                        bottom: 0;
                    }
                }
                .number {
                    bottom: 70px;
                    opacity: 1;
                }
            }
        }
    }
    .head-news-class {
        padding: 45px 0 0 65px;
        margin: 0;
    }
    .news-block-header {
        position: relative;
        z-index: 10;
    }
    .number {
        color: rgba(211, 211, 211, 0.4);
        font-size: 124px;
        line-height: 93px;
        font-weight: 400;

        &.colored {
            color: @main_color2;
        }
    }
    .news-block-content {
        position: relative;

        .number {
            position: absolute;
            top: -25px;
            left: -2px;
            z-index: 5;
        }
    }
    .arrow {
        background: none;
        font-size: 50px;

        &.prev {
            .center-absolute(50%, 0);
            left: -45px;
            margin-top: -60px;
        }
        &.next {
            .center-absolute(50%, 0);
            margin-top: -60px;
            left: auto;
            right: -45px;
        }
    }
}

#members-page-news { 
    margin:0;
    .item {
        margin-bottom:50px;
    }
}

// =news-home
//============================================================================*/
#news-home {
    padding: 100px 0 0 0;

    &.subpage {
        padding: 0;
    }
    .news-col {
        margin: 0;
        padding: 0 5px;
    }
    .link-absolute {
        z-index: 20;
    }
    .news-block {
        margin: 0 0 10px 0;
        padding: 0;
        position: relative;

        .gradient {
            z-index: 5;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 21%, rgba(0,0,0,0.65) 100%);
            background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 21%,rgba(0,0,0,0.65) 100%);
            background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 21%,rgba(0,0,0,0.65) 100%);
        }
        .news-block-photo {
            z-index: 4;
            position: relative;
        }
        .news-block-content {
            z-index: 20;
            position: absolute;
            bottom: 35px;
            left: 0;
            width: 100%;
            padding: 0 30px;

            .head-news-class {
                margin: 10px 0 0 0;
                color: @text_on_main_color;
                line-height: 35px;

                a {
                    color: @text_on_main_color;
                }
                .more-square {
                    border: 1px solid @text_on_main_color;
                    line-height: 26px;
                    font-size: 14px;
                    padding: 0px 8px 3px 11px;
                    text-align: center;
                    width: 26px;
                    margin: 0px 0 0px 12px;
                    position: relative;
                    top: -4px;
                }
            }
            .news-date {
                display: inline-block;
                font-size: 13px;
                line-height: 13px;
                padding: 6px;
                background: @main_color;
                color: @text_on_main_color;
            }
        }
    }
    .more-box {
        margin: 60px 0 0 0;
    }
}
.news-list-more {
    margin: 40px 0 0 0;
}

// =article-home
//============================================================================*/
@article_home: #dddddd;
@article_home_height: 720px;

#article-home {
    margin: 120px 0 0 0;
    height: @article_home_height;
    background: @article_home;

    .content-inside {
        padding: 45px 85px 85px 200px;

        .head-class {
            position: relative;
            z-index: 10;
            text-align: right;

            .big {
                font-size: 120px;
                font-weight: 300;
                text-align: right;
                letter-spacing: 20px;
                display: block;
                margin: 0 -200px 0 0;
            }
            .small {
                margin: 10px -290px 0 0;
                font-size: 60px;
                font-weight: 300;
                display: block;
                letter-spacing: 10px;
                color: @main_text_color;
            }
        }
        .text {
            margin: 50px 0 0 0;
            color: #777777;
        }
        .button {
            margin: 25px 0 0 0;
        }
    }
    .bg {
        height: @article_home_height;
        background-size: cover !important;
    }
}

// =partners-home
//============================================================================*/
#partners-home {
    padding: 100px 0 40px 0;

    .item {
        height: 200px;
        position: relative;

        .inside {
            height: 200px;
            position: relative;
            margin: 0 20px;
        }
        img {
            max-width: 100%;
            max-height: 150px;
            margin: 0 auto;
            .center-absolute();
            .filter();
            .transition();
        }
        &:hover {
            img {
                .filter(~"grayscale(0)");
            }
        }
    }
    #partners-home-slider {
        .arrow {
            background: none;
            font-size: 50px;

            &.prev {
                .center-absolute(50%, 0);
                left: -45px;
            }
            &.next {
                .center-absolute(50%, 0);
                left: auto;
                right: -45px;
            }
        }
    }
}

// =news
//============================================================================*/
.news-block {
    margin: 0 0 20px 0;
    padding: 0 0 20px 0;
    border-bottom: @main_border;

    .news-block-photo {
        overflow: hidden;

        img {
            transform: scale(1);
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -o-transform: scale(1);
            -ms-transform: scale(1);
            .transition();
        }
    }
    &:hover {
        .news-block-photo {
            img {
                transform: scale(1.1);
                -webkit-transform: scale(1.1);
                -moz-transform: scale(1.1);
                -o-transform: scale(1.1);
                -ms-transform: scale(1.1);
            }
        }
        .hover-bg {
            opacity: 1;
        }
    }
}
.news-single-header {
    text-align: center;
    border-right: 1px solid @main_color;
    padding: 55px 45px 45px 45px;

    .date {
        display: block;
        margin: 5px 0 0 0;
    }
}
.news-single-head {
    margin: 0 0 90px 0;
}
.news-single-text {
    padding: 0 0 0 35px;
}

#cities-list {
    padding: 30px 0 0 30px;

    .item {
        margin: 0 0 50px 0;

        .head-class {
            letter-spacing: 4px;
            margin: 0 0 30px 0;
        }
    }
}
.city-select {
    margin: 30px 65px 0 65px;
}

// =upload-tmp-list
//============================================================================*/
#upload-tmp-list {

    ul {
        list-style: none;
        padding: 0;

        li {
            position: relative;
            border-bottom: @main_border;
            padding: 10px 0;
        }
    }
    p {
        max-width: 90%;
        display: inline-block;
    }
    i {
        position: absolute;
        top: 8px;
        right: 0;
        margin: 5px 0 0 0;
        display: inline-block;
        float: right;
        color: @error_color;
    }
}
.dropzone .dz-preview, .dropzone-previews .dz-preview {
    width: 130px;
    overflow: hidden;
}
.dz_filename {
    height: 80px;
    overflow: hidden;
}

// =search
//============================================================================*/
.search-result-list {

    .search-item {
        margin: 0 0 30px 0;
        padding: 0 0 30px 0;
        border-bottom: @main_border;

        .search-item-head {
            color: #000000;
            font-size: 22px;
            font-weight: 400;
            line-height: 22px;
            text-align: left;
            margin: 0 0 30px 0;
        }
    }
}

// =footer
//============================================================================*/
@footer_font_size: @main_font_size;
@footer_bg: #ebe9ea;
@footer_color: @main_text_color;
@footer_padding: 0;
@footer_link_hover_color: @main_text_color;
@footer_box_height: 193px;

footer#footer {
    background: @footer_bg;
    padding: @footer_padding;
    color: @footer_color;
    font-size: @footer_font_size;

    .footer-box {
        height: @footer_box_height;
        background: @footer_bg;
        position: relative;

        &.dark {
            background: @main_color;
        }
        .inside {
            .center-absolute(50%, 0);
            width: 100%;
            text-align: center;

            img {
                margin: 0 auto;
            }
            .block {
                margin: 0;
            }
        }
        .to-top {
            color: @text_on_main_color;
            font-size: 60px;
            .center-absolute();
            left: auto;
            right: 5px;
        }
    }
    .copyright {
        margin: 10px 0 0 0;
    }
}

// --responsive
@import "../responsive/style_xs.less";
@import "../responsive/style_sm.less";
@import "../responsive/style_md.less";
@import "../responsive/style_lg.less";