// main file: ../style.less

// LG Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1450px) {
    
    // =header
    //========================================================================*/
    header#header {
        .search-box {
            margin: 23px 10px 0 10px;

            input {
                max-width: 65px;
            }
        }
    }

    // =menu
    //========================================================================*/
    #menu {
        li {
            a {
                padding: 20px 10px;

                &:hover, &:focus, &:active, &.active {
                    padding: 20px 10px;
                }
            }
        }
    }

    // =article-home
    //========================================================================*/
    #article-home {
        .content-inside {
            padding: 45px 50px 85px 50px;

            .head-class{
                .big {
                    font-size: 75px;
                    margin: 0 0 0 40px;
                    text-align: left;
                }
                .small {
                    margin: 10px 0 0 140px;
                    text-align: left;
                    font-size: 50px;
                }
            }
        }
    }

    // =tab-gallery
    //========================================================================*/
    .tab-gallery {
        .tab-gallery-thumbnails {
            padding: 0 30px;

            .arrow {
                &.prev {
                    left: -15px;
                }
                &.next {
                    right: -15px;
                }
            }
        }
    }

}
@media (min-width: 1451px) {
    #banners-reklama {
        .reklama {
            &.container {
                width:1630px; 
            }
        }
    }
}