// main file: ../style.less

// =gallery
//============================================================================*/
.tab-gallery {
    .tab-gallery-big {
        max-width: 680px;
        margin: 0 auto;
        height: 700px;
        position: relative;

        .item {
            height: 700px;
            position: relative;
        }

        img {
            .center-absolute();
            max-height: 100%;
            margin: 0 auto;
        }
    }
    .tab-gallery-thumbnails {
        margin: 80px 0 0 0;

        .item {
            cursor: pointer;
            height: 280px;
            position: relative;

            .inside {
                height: 100%;
                position: relative;
                margin: 0 5px;
                overflow: hidden;
            }
            img {
                margin: 0 auto;
                .center-absolute();
                max-height: 100%;
                max-width: none;
            }
            .active-bg {
                display: none;
                background: rgba(0, 0, 0, 0.6);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                text-align: center;

                i {
                    color: @main_color2;
                    font-size: 50px;
                    .center-absolute(0, 50%);
                    top: auto;
                    bottom: 20px;
                    margin-left: 5px;
                }
            }
            &.active {
                img {
                    .filter();
                }

                .active-bg {
                    display: block;
                }
            }
        }
    }

    .arrow {
        background: none;
        font-size: 50px;

        &.prev {
            .center-absolute(50%, 0);
            left: -45px;
        }
        &.next {
            .center-absolute(50%, 0);
            left: auto;
            right: -45px;
        }
    }
}