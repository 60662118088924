// main file: ../style.less

.slick-slider{
    z-index: 1;

    .slick-list, .slick-track{
        z-index: 1;
    }
}

// =banners-top
//============================================================================*/
@banners_top_color: #ffffff;
@banners_top_font_size: 40px;
@banners_top_height: 100%;
@banners_top_subpage_height: 480px;

#banners-top {
    height: @banners_top_height;
    color: @banners_top_color;

    z-index: 1;
    position: relative;

    > div {
        height: @banners_top_height;
        > div {
            height: @banners_top_height;
            > div {
                height: @banners_top_height;
            }
        }
    }

    .item {
        position: relative;
        height: @banners_top_height;
        background-size: cover !important;
        background-attachment: fixed !important;

        .banners-top-text {
            width: 100%;
            position: absolute;
            bottom: 200px;
            left: 0;
            padding: @container_padding;
        }
        .banners-head {
            font-family: @header_font_family;
            font-size: @banners_top_font_size;
            margin: 0;
            font-size: 67px;
            font-weight: 300;
            text-transform: uppercase;
            color: @main_color;
            letter-spacing: 25px;

            b {
                letter-spacing: 45px;
                font-size: 122px;
                font-weight: 500;
                text-align: right;
            }
        }
    }
    &.subpage {
        height: @banners_top_subpage_height;

        > div {
            height: @banners_top_subpage_height;
            > div {
                height: @banners_top_subpage_height;
                > div {
                    height: @banners_top_subpage_height;
                }
            }
        }

        .item {
            height: @banners_top_subpage_height;
            position: relative;

            .banners-top-text {
                bottom: 80px;
            }
        }
    }
    .arrow {
        background: none;
        font-size: 70px;
        color: @main_color;
        z-index: 1000;

        &.prev {
            .center-absolute(50%, 0);
            left: 50px;
            margin-top: -40px;
        }
        &.next {
            .center-absolute(50%, 0);
            margin-top: -40px;
            left: auto;
            right: 50px;
        }
    }
}
#banners-top-arrows {
    position: absolute;
    bottom: 18px;
    left: 15px;
    z-index: 150;
    color: @banners_top_color;

    button {
        background: none;
    }
}

// =banners-bottom-home
//============================================================================*/
#banners-bottom-home {
    margin: 0;

    a {
        color: @main_text_color;
    }
    .item {
        height: 193px;
        background-size: cover !important;
        background-position: right top !Important;
        position: relative;

        .text {
            position: absolute;
            left: 50px;
            bottom: 35px;
            z-index: 30;
            .transition();
        }
        .colored {
            .transition();
        }
        .head-class {
            margin: 0;
        }
        .ico {
            position: absolute;
            top: 32px;
            left: 50px;
            color: rgba(16, 16, 16, 0.07);
            font-size: 50px;
            font-weight: 400;
            z-index: 30;
            .transition();
        }
        .bg {
            background: @main_color2;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            .transition();
            z-index: 20;
        }
        &:hover, &:focus, &:active {
            color: @text_on_main_color;

            .bg {
                opacity: 0.8;
            }
            .ico {
                color: @text_on_main_color;
            }
            .colored {
                color: @text_on_main_color;
            }
        }
    }
    > div {
        &:first-child {
            .item {
                .ico {
                    color: rgba(254, 254, 254, 0.07);
                }
                &:hover, &:focus, &:active {
                    .ico {
                        color: @text_on_main_color;
                    }
                }
            }
        }
    }
}

#banners-reklama {
    margin:80px 0px 10px 0px;
    text-align:center;
    img {
        display:inline;
        margin:15px 0px;
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
        opacity:0.5;
        .transition();
        &:hover {
            -webkit-filter: grayscale(0);
            filter: grayscale(0);
            opacity:1;
        }
    }
    
}