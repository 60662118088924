.back-link {
  display: inline-block;
  margin: 0 0 20px 0;
  position: absolute;
  top: 0;
  right: 0;
}
.link-absolute {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.padding0 {
  padding: 0;
}
.colored {
  color: #933956;
}
.slick-disabled {
  color: #dedede;
}
.row-big {
  margin-left: -40px;
  margin-right: -40px;
  margin-bottom: 40px;
}
.row-big > div {
  padding: 0 40px;
}
.alert {
  margin-bottom: 50px;
}
.display-block {
  display: block;
}
.container.big {
  padding: 0 40px;
}
.hover-bg {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: All 0.5s ease;
  -webkit-transition: All 0.5s ease;
  -moz-transition: All 0.5s ease;
  -o-transition: All 0.5s ease;
  background: rgba(46, 46, 46, 0.5);
  background: -moz-linear-gradient(top, rgba(46, 46, 46, 0.5) 0%, #000000 100%);
  background: -webkit-linear-gradient(top, rgba(46, 46, 46, 0.5) 0%, #000000 100%);
  background: linear-gradient(to bottom, rgba(46, 46, 46, 0.5) 0%, #000000 100%);
  z-index: 15;
}
.block {
  margin: 0 0 30px 0;
  position: relative;
  clear: both;
}
.block figcaption {
  margin: 20px 0 0 0;
  text-align: center;
}
.block .block-float-left {
  float: left;
  padding: 0 30px 15px 0;
}
.block .block-float-right {
  float: right;
  padding: 0 0 15px 30px;
}
.block .ico-file {
  margin: 0 15px 0 0;
  position: absolute;
  top: 0;
  left: 15px;
}
.block .link-file-mini span {
  min-height: 30px;
  display: block;
  padding: 4px 0 0 40px;
}
.block .link-file-medium span {
  min-height: 50px;
  display: block;
  padding: 12px 0 0 70px;
}
.block .link-file-big span {
  min-height: 73px;
  display: block;
  padding: 23px 0 0 90px;
}
.block .head-block {
  color: #474747;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin: 0 0 30px 0;
}
.block .block-slide-content {
  display: none;
  margin: 20px 0 0 0;
}
.block .block-slide-content .block:last-child {
  margin: 0;
}
.block .block-slide-header {
  cursor: pointer;
  padding: 10px 15px;
  background: #2480d5;
  color: #ffffff;
}
.block .block-slide-header i {
  float: right;
  margin: 5px 0 0 0;
}
.block .block-slide-tab {
  margin: 0 0 30px 0;
}
.block .block-tabs-id {
  background: #ffffff;
  color: #000000;
}
.block .block-tabs-id .resp-arrow {
  border-top: 12px solid #000000;
}
.block .block-tabs-id.resp-tab-active,
.block .block-tabs-id .resp-tab-active {
  background: #2480d5 !important;
  color: #ffffff !important;
  border-top: 1px solid #2480d5 !important;
  border-color: #2480d5 !important;
}
.block .block-tabs-id.resp-tab-active .resp-arrow,
.block .block-tabs-id .resp-tab-active .resp-arrow {
  border-top: none;
  border-bottom: 12px solid #ffffff;
}
.block .resp-tab-content {
  border-color: #2480d5;
}
.block .resp-tab-content .block:last-child {
  margin: 0;
}
.block.block-multiple-column-1 {
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
}
.block.block-multiple-column-2 {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}
.block.block-multiple-column-3 {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
}
.head-class {
  font-family: 'Oswald', sans-serif;
  font-size: 24px;
  font-weight: 300;
  margin: 0 0 30px 0;
  position: relative;
}
.head-class a {
  color: #0a0a0a;
}
.head-class.semi-medium {
  font-size: 30px;
  line-height: 35px;
  font-weight: 400;
  text-transform: uppercase;
}
.head-class.big {
  text-transform: uppercase;
  font-size: 60px;
  line-height: 65px;
  font-weight: 300;
  margin: 0 0 60px 0;
  color: #1c1c1d;
  text-align: center;
}
.head-class.big:after {
  display: block;
  margin: 30px auto 0 auto;
  content: "";
  width: 37px;
  height: 3px;
  background: #933956;
}
.head-class.medium {
  font-size: 30px;
  font-weight: 300;
  text-transform: uppercase;
  margin: 0 0 40px 0;
  letter-spacing: 4px;
}
.head-class.medium:after {
  display: block;
  margin: 20px 0 0 0;
  content: "";
  width: 37px;
  height: 3px;
  background: #933956;
}
.head-class.border-right-bottom:after {
  position: absolute;
  right: 5px;
}
.head-class.no-upper {
  text-transform: none;
}
.head-class.no-border:after {
  display: none;
}
.head-class.no-margin {
  margin: 0;
}
.head-news-class {
  font-family: 'Oswald', sans-serif;
  margin: 0 0 30px 0;
  position: relative;
  color: #0a0a0a;
  font-size: 24px;
  font-weight: 300;
}
.head-news-class a {
  color: #0a0a0a;
}
.head-news-class.semi-medium {
  font-size: 30px;
  line-height: 35px;
  font-weight: 400;
  text-transform: uppercase;
}
.head-news-class.big {
  text-transform: uppercase;
  font-size: 60px;
  line-height: 65px;
  font-weight: 300;
  margin: 0 0 60px 0;
  color: #1c1c1d;
  text-align: center;
}
.head-news-class.big:after {
  display: block;
  margin: 30px auto 0 auto;
  content: "";
  width: 37px;
  height: 3px;
  background: #933956;
}
.head-news-class.medium {
  font-size: 30px;
  font-weight: 300;
  text-transform: uppercase;
  margin: 0 0 40px 0;
  letter-spacing: 4px;
}
.head-news-class.medium:after {
  display: block;
  margin: 20px 0 0 0;
  content: "";
  width: 37px;
  height: 3px;
  background: #933956;
}
.head-news-class.border-right-bottom:after {
  position: absolute;
  right: 5px;
}
.head-news-class.no-upper {
  text-transform: none;
}
.head-news-class.no-border:after {
  display: none;
}
.head-news-class.no-margin {
  margin: 0;
}
.button {
  font-family: 'Oswald', sans-serif;
  color: #933956;
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  background: none;
  border: none;
  display: inline-block;
  padding: 20px 15px 10px 15px;
  border-top: 3px solid #933956;
}
.button:hover,
.button:focus,
.button:active {
  color: #491c2b;
  border-color: #491c2b;
}
.btn {
  outline: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.btn-primary {
  background: #933956;
  border: 1px solid #6e2b40;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background: #491c2b;
}
#breadcrumbs {
  line-height: 31px;
  list-style: none;
  padding: 0;
}
#breadcrumbs li {
  float: left;
  margin: 0 10px 0 0;
}
#breadcrumbs li a {
  color: #933956;
}
#breadcrumbs li a:hover,
#breadcrumbs li a:focus,
#breadcrumbs li a:active {
  text-decoration: underline;
}
#breadcrumbs li:before {
  content: '›';
  margin: 0 10px 0 0;
}
#breadcrumbs li:first-child:before {
  display: none;
}
.svg-map {
  max-width: 100%;
}
.cls-1 {
  fill: #ffffff;
  cursor: pointer;
}
.cls-1:hover,
.cls-1.active {
  fill: #933956;
}
.cls-2 {
  fill: #933956;
}
.modal-content {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
#contact-map {
  height: 300px;
  width: 100%;
}
#contact-map .block {
  margin: 0;
}
.news-single-top {
  position: relative;
}
.social-share {
  position: absolute;
  top: -30px;
  right: 0;
}
.social-share div.item {
  display: block;
  float: left;
  margin: 0 10px 0 0;
}
.social-share div.item.fb-item {
  margin-top: -10px;
}
.social-share div.item .email-share {
  position: relative;
  background: #A1A1A1;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-size: 13px;
  padding: 3px 8px;
  color: #ffffff;
  top: -5px;
  transition: none;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
}
.social-share div.item .email-share i {
  margin: 0 3px 0 0;
}
.social-share div.item .email-share:hover {
  background: #6e6e6e;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}
.form-control {
  border: 1px solid #d8d8d8;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.06);
  height: 48px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.form-control:focus {
  border: 1px solid #933956;
  -moz-box-shadow:  0 3px 0 0 #c66c89;
  -webkit-box-shadow:  0 3px 0 0 #c66c89;
  box-shadow:  0 3px 0 0 #c66c89;
}
textarea.form-control {
  padding: 12px;
}
.contact-form-box textarea {
  height: 254px;
}
.contact-form-box .button-box {
  margin: 40px 0 0 0;
}
input[type=checkbox],
input[type=radio] {
  margin: 7px 0 0;
  margin-top: 1px;
  line-height: normal;
}
label {
  font-weight: 300;
  margin: 0 0 10px 0;
}
.checkbox label,
.radio label {
  font-weight: 300;
  padding-left: 30px;
}
.input-group-addon {
  background: none;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.06);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.input-group.small {
  max-width: 170px;
}
.styled-select {
  position: relative;
}
.styled-select select {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  width: 100%;
  height: 48px;
  position: relative;
  z-index: 10;
  cursor: pointer;
}
.styled-select i {
  position: absolute;
  right: 20px;
  top: 16px;
  color: #646464;
}
.styled-select:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 50px;
  height: 100%;
  background: #d8d8d8;
  height: 48px;
  width: 1px;
}
.styled-select select::-ms-expand {
  display: none;
}
@media screen and (min-width: 0) {
  .styled-select select {
    background: none;
  }
}
.casting-form-content {
  margin: 0 0 110px 0;
}
.casting-form-content .head-class {
  letter-spacing: 4px;
}
.casting-form-content textarea {
  height: 135px;
}
.casting-form-content .file-input {
  display: block;
  border: 1px solid #b2b2b2;
  background: #eeeeee;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.06);
  height: 110px;
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
  text-align: center;
  padding: 25px 0;
  cursor: pointer;
  margin: 0 0 70px 0;
  transition: All 0.5s ease;
  -webkit-transition: All 0.5s ease;
  -moz-transition: All 0.5s ease;
  -o-transition: All 0.5s ease;
}
.casting-form-content .file-input .small {
  font-size: 16px;
}
.casting-form-content .file-input:hover,
.casting-form-content .file-input:active,
.casting-form-content .file-input:focus {
  background: #bbbbbb;
}
.dropzone .dz-preview .dz-error-message,
.dropzone-previews .dz-preview .dz-error-message {
  left: 0 !important;
}
.check {
  position: absolute;
  margin: 4px 0 0 0px;
}
.check label {
  display: block;
  border: 1px solid #d8d8d8;
  background: none;
  width: 17px;
  height: 17px;
  min-height: 17px;
  padding: 0;
}
.check label i {
  display: block;
  text-align: center;
  opacity: 0;
  color: #1b1b1b;
  font-size: 18px;
  line-height: 13px;
}
.check input {
  opacity: 0;
}
.check input:checked + label i {
  opacity: 1;
}
#menu-bar {
  background: rgba(0, 0, 0, 0.77);
  height: 80px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 80px;
  padding: 0 30px 0 80px;
  z-index: 9999;
}
#menu-bar .logo-menu {
  display: block;
  float: left;
  position: relative;
  margin: 20px 20px 0 0;
}
#menu-bar.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
#menu-bar.fixed .logo-menu {
  display: block;
}
#menu {
  list-style: none;
  padding: 0;
}
#menu li {
  float: left;
}
#menu li a {
  padding: 20px 20px;
  font-family: 'Oswald', sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
  background: none;
  line-height: 40px;
  text-transform: uppercase;
}
#menu li a:after {
  transition: All 0.5s ease;
  -webkit-transition: All 0.5s ease;
  -moz-transition: All 0.5s ease;
  -o-transition: All 0.5s ease;
  display: block;
  margin: 0 auto;
  content: "";
  width: 70%;
  height: 3px;
  background: #ffffff;
  opacity: 0;
}
#menu li a:hover,
#menu li a:focus,
#menu li a:active,
#menu li a.active {
  color: #ffffff;
  background: none;
  padding: 20px 20px;
}
#menu li a:hover:after,
#menu li a:focus:after,
#menu li a:active:after,
#menu li a.active:after {
  opacity: 1;
}
#menu li .dropdown-menu li {
  float: none;
}
#menu li .dropdown-menu li a {
  background: #ffffff;
  color: #393939;
}
#menu li .dropdown-menu li a:hover,
#menu li .dropdown-menu li a:focus,
#menu li .dropdown-menu li a:active,
#menu li .dropdown-menu li a.active {
  color: #ffffff;
  background: #2480D5;
}
.navbar {
  float: left;
  margin: 0;
  padding: 0;
  border: none;
}
.navbar-collapse {
  padding: 0;
}
.col-menu {
  position: relative;
  left: -185px;
  transition: All 0.5s ease;
  -webkit-transition: All 0.5s ease;
  -moz-transition: All 0.5s ease;
  -o-transition: All 0.5s ease;
}
.fixed .col-menu {
  left: -50px;
}
.submenu-box {
  background: #1b1b1b;
  padding: 20px;
}
#submenu {
  padding: 0;
  list-style: none;
}
#submenu li a {
  display: block;
  padding: 5px 15px;
  color: #ffffff;
}
#submenu li a:hover,
#submenu li a:focus,
#submenu li a:active,
#submenu li a.active {
  background: #000000;
}
#submenu li .inside-submenu {
  padding: 0;
  list-style: none;
}
#submenu li .inside-submenu li a {
  padding-left: 40px;
}
#submenu li .inside-submenu li a:hover,
#submenu li .inside-submenu li a:focus,
#submenu li .inside-submenu li a:active,
#submenu li .inside-submenu li a.active {
  background: none;
  color: #000000;
}
.slick-slider {
  z-index: 1;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  z-index: 1;
}
#banners-top {
  height: 100%;
  color: #ffffff;
  z-index: 1;
  position: relative;
}
#banners-top > div {
  height: 100%;
}
#banners-top > div > div {
  height: 100%;
}
#banners-top > div > div > div {
  height: 100%;
}
#banners-top .item {
  position: relative;
  height: 100%;
  background-size: cover !important;
  background-attachment: fixed !important;
}
#banners-top .item .banners-top-text {
  width: 100%;
  position: absolute;
  bottom: 200px;
  left: 0;
  padding: 0 80px;
}
#banners-top .item .banners-head {
  font-family: 'Oswald', sans-serif;
  font-size: 40px;
  margin: 0;
  font-size: 67px;
  font-weight: 300;
  text-transform: uppercase;
  color: #1b1b1b;
  letter-spacing: 25px;
}
#banners-top .item .banners-head b {
  letter-spacing: 45px;
  font-size: 122px;
  font-weight: 500;
  text-align: right;
}
#banners-top.subpage {
  height: 480px;
}
#banners-top.subpage > div {
  height: 480px;
}
#banners-top.subpage > div > div {
  height: 480px;
}
#banners-top.subpage > div > div > div {
  height: 480px;
}
#banners-top.subpage .item {
  height: 480px;
  position: relative;
}
#banners-top.subpage .item .banners-top-text {
  bottom: 80px;
}
#banners-top .arrow {
  background: none;
  font-size: 70px;
  color: #1b1b1b;
  z-index: 1000;
}
#banners-top .arrow.prev {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  left: 50px;
  margin-top: -40px;
}
#banners-top .arrow.next {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  margin-top: -40px;
  left: auto;
  right: 50px;
}
#banners-top-arrows {
  position: absolute;
  bottom: 18px;
  left: 15px;
  z-index: 150;
  color: #ffffff;
}
#banners-top-arrows button {
  background: none;
}
#banners-bottom-home {
  margin: 0;
}
#banners-bottom-home a {
  color: #0a0a0a;
}
#banners-bottom-home .item {
  height: 193px;
  background-size: cover !important;
  background-position: right top !Important;
  position: relative;
}
#banners-bottom-home .item .text {
  position: absolute;
  left: 50px;
  bottom: 35px;
  z-index: 30;
  transition: All 0.5s ease;
  -webkit-transition: All 0.5s ease;
  -moz-transition: All 0.5s ease;
  -o-transition: All 0.5s ease;
}
#banners-bottom-home .item .colored {
  transition: All 0.5s ease;
  -webkit-transition: All 0.5s ease;
  -moz-transition: All 0.5s ease;
  -o-transition: All 0.5s ease;
}
#banners-bottom-home .item .head-class {
  margin: 0;
}
#banners-bottom-home .item .ico {
  position: absolute;
  top: 32px;
  left: 50px;
  color: rgba(16, 16, 16, 0.07);
  font-size: 50px;
  font-weight: 400;
  z-index: 30;
  transition: All 0.5s ease;
  -webkit-transition: All 0.5s ease;
  -moz-transition: All 0.5s ease;
  -o-transition: All 0.5s ease;
}
#banners-bottom-home .item .bg {
  background: #933956;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: All 0.5s ease;
  -webkit-transition: All 0.5s ease;
  -moz-transition: All 0.5s ease;
  -o-transition: All 0.5s ease;
  z-index: 20;
}
#banners-bottom-home .item:hover,
#banners-bottom-home .item:focus,
#banners-bottom-home .item:active {
  color: #ffffff;
}
#banners-bottom-home .item:hover .bg,
#banners-bottom-home .item:focus .bg,
#banners-bottom-home .item:active .bg {
  opacity: 0.8;
}
#banners-bottom-home .item:hover .ico,
#banners-bottom-home .item:focus .ico,
#banners-bottom-home .item:active .ico {
  color: #ffffff;
}
#banners-bottom-home .item:hover .colored,
#banners-bottom-home .item:focus .colored,
#banners-bottom-home .item:active .colored {
  color: #ffffff;
}
#banners-bottom-home > div:first-child .item .ico {
  color: rgba(254, 254, 254, 0.07);
}
#banners-bottom-home > div:first-child .item:hover .ico,
#banners-bottom-home > div:first-child .item:focus .ico,
#banners-bottom-home > div:first-child .item:active .ico {
  color: #ffffff;
}
#banners-reklama {
  margin: 80px 0px 10px 0px;
  text-align: center;
}
#banners-reklama img {
  display: inline;
  margin: 15px 0px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.5;
  transition: All 0.5s ease;
  -webkit-transition: All 0.5s ease;
  -moz-transition: All 0.5s ease;
  -o-transition: All 0.5s ease;
}
#banners-reklama img:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}
.tab-gallery .tab-gallery-big {
  max-width: 680px;
  margin: 0 auto;
  height: 700px;
  position: relative;
}
.tab-gallery .tab-gallery-big .item {
  height: 700px;
  position: relative;
}
.tab-gallery .tab-gallery-big img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  max-height: 100%;
  margin: 0 auto;
}
.tab-gallery .tab-gallery-thumbnails {
  margin: 80px 0 0 0;
}
.tab-gallery .tab-gallery-thumbnails .item {
  cursor: pointer;
  height: 280px;
  position: relative;
}
.tab-gallery .tab-gallery-thumbnails .item .inside {
  height: 100%;
  position: relative;
  margin: 0 5px;
  overflow: hidden;
}
.tab-gallery .tab-gallery-thumbnails .item img {
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  max-height: 100%;
  max-width: none;
}
.tab-gallery .tab-gallery-thumbnails .item .active-bg {
  display: none;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}
.tab-gallery .tab-gallery-thumbnails .item .active-bg i {
  color: #933956;
  font-size: 50px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  top: auto;
  bottom: 20px;
  margin-left: 5px;
}
.tab-gallery .tab-gallery-thumbnails .item.active img {
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
  filter: grayscale(1);
}
.tab-gallery .tab-gallery-thumbnails .item.active .active-bg {
  display: block;
}
.tab-gallery .arrow {
  background: none;
  font-size: 50px;
}
.tab-gallery .arrow.prev {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  left: -45px;
}
.tab-gallery .arrow.next {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  left: auto;
  right: -45px;
}
.partners-list .partners-row > div:last-child .partners-box {
  border-right: 1px solid #d8d8d8;
}
.partners-list .partners-row:first-child .partners-box {
  border-top: 1px solid #d8d8d8;
}
.partners-list .partners-box {
  height: 320px;
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  overflow: hidden;
}
.partners-list .partners-box .inside {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.partners-list .partners-box .inside img {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transition: All 0.5s ease;
  -webkit-transition: All 0.5s ease;
  -moz-transition: All 0.5s ease;
  -o-transition: All 0.5s ease;
  max-width: 60%;
  margin: 0 auto;
}
.partners-list .partners-box .inside .head-class {
  margin: 0;
}
.partners-list .partners-box .inside .head-class:after {
  display: block;
  margin: 20px auto 0 auto;
  content: "";
  width: 37px;
  height: 3px;
  background: #ffffff;
}
.partners-list .partners-box.head {
  background: #933956;
  border-color: #933956;
  color: #ffffff;
}
.partners-list .partners-box:hover .inside img {
  transition: All 0.5s ease;
  -webkit-transition: All 0.5s ease;
  -moz-transition: All 0.5s ease;
  -o-transition: All 0.5s ease;
  transform: scale(1.4);
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  -o-transform: scale(1.4);
  -ms-transform: scale(1.4);
}
body,
html {
  height: 100%;
}
body {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
  color: #0a0a0a;
  background: #ffffff;
  text-decoration: none;
  padding: 0;
  margin: 0 auto;
}
a {
  color: #933956;
  text-decoration: none;
  transition: All 0.5s ease;
  -webkit-transition: All 0.5s ease;
  -moz-transition: All 0.5s ease;
  -o-transition: All 0.5s ease;
  outline: none;
}
a:hover,
a:focus,
a:active {
  color: #491c2b;
  text-decoration: none;
  outline: none;
}
button {
  border: none;
  outline: none;
}
.content {
  margin: 80px 0 80px 0;
}
.content-contact {
  margin: 80px 0 0 0;
}
label.error {
  display: none;
  color: #F61237;
  font-weight: 300;
}
.my-container {
  padding: 0 80px;
}
header#header {
  height: 100%;
}
header#header #top {
  padding: 0 85px;
}
header#header .container {
  position: relative;
}
header#header .search-box {
  float: left;
  margin: 23px 10px 0 20px;
  position: relative;
  z-index: 50;
}
header#header .search-box button {
  background: none;
  color: #ffffff;
  margin: 0 5px 0 0;
}
header#header .search-box input {
  background: none;
  border: none;
  outline: none;
  color: #ffffff;
}
header#header .social {
  float: right;
  color: #ffffff;
  font-size: 30px;
  font-weight: 400;
  line-height: 40px;
  margin: 22px 0 0 0;
}
header#header .social a {
  color: #ffffff;
  margin: 0 8px;
}
header#header .social a:hover,
header#header .social a:focus,
header#header .social a:active {
  color: #933956;
}
header#header.subpage {
  height: 480px;
}
.logo {
  z-index: 9999;
  position: absolute;
  top: 20px;
  left: 0;
}
.logo h1 {
  margin: 0;
}
.logo a {
  display: block;
  background: rgba(0, 0, 0, 0.77);
  padding: 25px 35px;
}
.logo.subpage {
  display: none;
}
.flags {
  float: right;
  margin: 10px 15px 0 0;
}
.flags a {
  display: inline-block;
  margin: 0 0 0 5px;
}
.archive-box .archive-item {
  padding: 5px 0 5px 15px;
  border-left: 1px solid #E8E8E8;
  margin: 0 0 0 25px;
}
.archive-box .year {
  font-size: 25px;
  margin: 10px 0 10px 0;
}
.archive-box .year:first-child {
  margin: 0 0 10px 0;
}
#members-home {
  padding: 100px 0 0 0;
}
#members-home-slider,
#members-page-news {
  margin: 0 -5px;
}
#members-home-slider .item,
#members-page-news .item {
  cursor: pointer;
}
#members-home-slider .item .news-block-photo,
#members-page-news .item .news-block-photo {
  max-height: 600px;
  overflow: hidden;
  margin: 0 5px;
  position: relative;
  z-index: 10;
}
#members-home-slider .item .news-block-photo img,
#members-page-news .item .news-block-photo img {
  width: 100%;
  margin: 0 auto;
}
#members-home-slider .item .hover-bg .inside,
#members-page-news .item .hover-bg .inside {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  margin-top: -30px;
  text-align: center;
}
#members-home-slider .item .hover-bg .inside .head-news-class,
#members-page-news .item .hover-bg .inside .head-news-class {
  color: #ffffff;
  padding: 0;
  margin: 0 0 30px 0;
  position: relative;
  bottom: -50px;
  transition: All 0.5s ease;
  -webkit-transition: All 0.5s ease;
  -moz-transition: All 0.5s ease;
  -o-transition: All 0.5s ease;
}
#members-home-slider .item .hover-bg .inside .head-news-class a,
#members-page-news .item .hover-bg .inside .head-news-class a {
  color: #ffffff;
}
#members-home-slider .item .hover-bg .inside .text-small,
#members-page-news .item .hover-bg .inside .text-small {
  color: #999999;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  position: relative;
  bottom: -50px;
  transition: All 0.5s ease;
  -webkit-transition: All 0.5s ease;
  -moz-transition: All 0.5s ease;
  -o-transition: All 0.5s ease;
}
#members-home-slider .item .hover-bg .inside .text-small p,
#members-page-news .item .hover-bg .inside .text-small p {
  margin: 0;
}
#members-home-slider .item .hover-bg .number,
#members-page-news .item .hover-bg .number {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 20px;
  transition: All 0.5s ease;
  -webkit-transition: All 0.5s ease;
  -moz-transition: All 0.5s ease;
  -o-transition: All 0.5s ease;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  opacity: 0;
}
#members-home-slider .item:hover .hover-bg,
#members-page-news .item:hover .hover-bg {
  opacity: 1;
}
#members-home-slider .item:hover .hover-bg .inside .head-news-class,
#members-page-news .item:hover .hover-bg .inside .head-news-class {
  bottom: 0;
}
#members-home-slider .item:hover .hover-bg .inside .text-small,
#members-page-news .item:hover .hover-bg .inside .text-small {
  bottom: 0;
}
#members-home-slider .item:hover .hover-bg .number,
#members-page-news .item:hover .hover-bg .number {
  bottom: 70px;
  opacity: 1;
}
#members-home-slider .head-news-class,
#members-page-news .head-news-class {
  padding: 45px 0 0 65px;
  margin: 0;
}
#members-home-slider .news-block-header,
#members-page-news .news-block-header {
  position: relative;
  z-index: 10;
}
#members-home-slider .number,
#members-page-news .number {
  color: rgba(211, 211, 211, 0.4);
  font-size: 124px;
  line-height: 93px;
  font-weight: 400;
}
#members-home-slider .number.colored,
#members-page-news .number.colored {
  color: #933956;
}
#members-home-slider .news-block-content,
#members-page-news .news-block-content {
  position: relative;
}
#members-home-slider .news-block-content .number,
#members-page-news .news-block-content .number {
  position: absolute;
  top: -25px;
  left: -2px;
  z-index: 5;
}
#members-home-slider .arrow,
#members-page-news .arrow {
  background: none;
  font-size: 50px;
}
#members-home-slider .arrow.prev,
#members-page-news .arrow.prev {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  left: -45px;
  margin-top: -60px;
}
#members-home-slider .arrow.next,
#members-page-news .arrow.next {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  margin-top: -60px;
  left: auto;
  right: -45px;
}
#members-page-news {
  margin: 0;
}
#members-page-news .item {
  margin-bottom: 50px;
}
#news-home {
  padding: 100px 0 0 0;
}
#news-home.subpage {
  padding: 0;
}
#news-home .news-col {
  margin: 0;
  padding: 0 5px;
}
#news-home .link-absolute {
  z-index: 20;
}
#news-home .news-block {
  margin: 0 0 10px 0;
  padding: 0;
  position: relative;
}
#news-home .news-block .gradient {
  z-index: 5;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 21%, rgba(0, 0, 0, 0.65) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 21%, rgba(0, 0, 0, 0.65) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 21%, rgba(0, 0, 0, 0.65) 100%);
}
#news-home .news-block .news-block-photo {
  z-index: 4;
  position: relative;
}
#news-home .news-block .news-block-content {
  z-index: 20;
  position: absolute;
  bottom: 35px;
  left: 0;
  width: 100%;
  padding: 0 30px;
}
#news-home .news-block .news-block-content .head-news-class {
  margin: 10px 0 0 0;
  color: #ffffff;
  line-height: 35px;
}
#news-home .news-block .news-block-content .head-news-class a {
  color: #ffffff;
}
#news-home .news-block .news-block-content .head-news-class .more-square {
  border: 1px solid #ffffff;
  line-height: 26px;
  font-size: 14px;
  padding: 0px 8px 3px 11px;
  text-align: center;
  width: 26px;
  margin: 0px 0 0px 12px;
  position: relative;
  top: -4px;
}
#news-home .news-block .news-block-content .news-date {
  display: inline-block;
  font-size: 13px;
  line-height: 13px;
  padding: 6px;
  background: #1b1b1b;
  color: #ffffff;
}
#news-home .more-box {
  margin: 60px 0 0 0;
}
.news-list-more {
  margin: 40px 0 0 0;
}
#article-home {
  margin: 120px 0 0 0;
  height: 720px;
  background: #dddddd;
}
#article-home .content-inside {
  padding: 45px 85px 85px 200px;
}
#article-home .content-inside .head-class {
  position: relative;
  z-index: 10;
  text-align: right;
}
#article-home .content-inside .head-class .big {
  font-size: 120px;
  font-weight: 300;
  text-align: right;
  letter-spacing: 20px;
  display: block;
  margin: 0 -200px 0 0;
}
#article-home .content-inside .head-class .small {
  margin: 10px -290px 0 0;
  font-size: 60px;
  font-weight: 300;
  display: block;
  letter-spacing: 10px;
  color: #0a0a0a;
}
#article-home .content-inside .text {
  margin: 50px 0 0 0;
  color: #777777;
}
#article-home .content-inside .button {
  margin: 25px 0 0 0;
}
#article-home .bg {
  height: 720px;
  background-size: cover !important;
}
#partners-home {
  padding: 100px 0 40px 0;
}
#partners-home .item {
  height: 200px;
  position: relative;
}
#partners-home .item .inside {
  height: 200px;
  position: relative;
  margin: 0 20px;
}
#partners-home .item img {
  max-width: 100%;
  max-height: 150px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
  filter: grayscale(1);
  transition: All 0.5s ease;
  -webkit-transition: All 0.5s ease;
  -moz-transition: All 0.5s ease;
  -o-transition: All 0.5s ease;
}
#partners-home .item:hover img {
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
  filter: grayscale(0);
}
#partners-home #partners-home-slider .arrow {
  background: none;
  font-size: 50px;
}
#partners-home #partners-home-slider .arrow.prev {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  left: -45px;
}
#partners-home #partners-home-slider .arrow.next {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  left: auto;
  right: -45px;
}
.news-block {
  margin: 0 0 20px 0;
  padding: 0 0 20px 0;
  border-bottom: 1px solid #d8d8d8;
}
.news-block .news-block-photo {
  overflow: hidden;
}
.news-block .news-block-photo img {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transition: All 0.5s ease;
  -webkit-transition: All 0.5s ease;
  -moz-transition: All 0.5s ease;
  -o-transition: All 0.5s ease;
}
.news-block:hover .news-block-photo img {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
}
.news-block:hover .hover-bg {
  opacity: 1;
}
.news-single-header {
  text-align: center;
  border-right: 1px solid #1b1b1b;
  padding: 55px 45px 45px 45px;
}
.news-single-header .date {
  display: block;
  margin: 5px 0 0 0;
}
.news-single-head {
  margin: 0 0 90px 0;
}
.news-single-text {
  padding: 0 0 0 35px;
}
#cities-list {
  padding: 30px 0 0 30px;
}
#cities-list .item {
  margin: 0 0 50px 0;
}
#cities-list .item .head-class {
  letter-spacing: 4px;
  margin: 0 0 30px 0;
}
.city-select {
  margin: 30px 65px 0 65px;
}
#upload-tmp-list ul {
  list-style: none;
  padding: 0;
}
#upload-tmp-list ul li {
  position: relative;
  border-bottom: 1px solid #d8d8d8;
  padding: 10px 0;
}
#upload-tmp-list p {
  max-width: 90%;
  display: inline-block;
}
#upload-tmp-list i {
  position: absolute;
  top: 8px;
  right: 0;
  margin: 5px 0 0 0;
  display: inline-block;
  float: right;
  color: #F61237;
}
.dropzone .dz-preview,
.dropzone-previews .dz-preview {
  width: 130px;
  overflow: hidden;
}
.dz_filename {
  height: 80px;
  overflow: hidden;
}
.search-result-list .search-item {
  margin: 0 0 30px 0;
  padding: 0 0 30px 0;
  border-bottom: 1px solid #d8d8d8;
}
.search-result-list .search-item .search-item-head {
  color: #000000;
  font-size: 22px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  margin: 0 0 30px 0;
}
footer#footer {
  background: #ebe9ea;
  padding: 0;
  color: #0a0a0a;
  font-size: 16px;
}
footer#footer .footer-box {
  height: 193px;
  background: #ebe9ea;
  position: relative;
}
footer#footer .footer-box.dark {
  background: #1b1b1b;
}
footer#footer .footer-box .inside {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  width: 100%;
  text-align: center;
}
footer#footer .footer-box .inside img {
  margin: 0 auto;
}
footer#footer .footer-box .inside .block {
  margin: 0;
}
footer#footer .footer-box .to-top {
  color: #ffffff;
  font-size: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  left: auto;
  right: 5px;
}
footer#footer .copyright {
  margin: 10px 0 0 0;
}
@media (max-width: 767px) {
  .block .block-float-left {
    float: none;
    padding: 0 0 15px 0;
  }
  .block .block-float-right {
    float: none;
    padding: 0 0 15px 0;
  }
  .block .link-file-mini span,
  .block .link-file-medium span,
  .block .link-file-big span {
    margin: 0 0 15px 0;
  }
  .block.block-type-4 img {
    margin: 15px auto 0 auto;
  }
  .block img {
    margin: 0 auto 15px auto;
  }
  .block.block-multiple-column-1 {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
  .block.block-multiple-column-2 {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
  .block.block-multiple-column-3 {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
  .content-contact {
    margin: 80px 0 80px 0;
  }
  .back-link {
    right: 15px;
  }
  .row-big {
    margin-left: -15px;
    margin-right: -15px;
  }
  .row-big > div {
    padding: 0 15px;
  }
  .container.big {
    padding: 0 15px;
  }
  header#header .search-box {
    margin: 0;
    position: absolute;
    top: 24px;
    left: 60px;
  }
  header#header .search-box input {
    height: 32px;
  }
  header#header .social {
    position: absolute;
    top: 0;
    right: 15px;
    font-size: 25px;
  }
  header#header .social input {
    max-width: 100px;
  }
  .logo {
    top: 100px;
  }
  .logo img {
    max-width: 200px;
  }
  .navbar-toggle {
    background: none;
    float: left;
    margin: 23px 0 0 10px;
  }
  .navbar-toggle .icon-bar {
    background: #ffffff;
  }
  .navbar {
    float: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .navbar-collapse {
    -moz-box-shadow:  0 0 0 0 #000000;
    -webkit-box-shadow:  0 0 0 0 #000000;
    box-shadow:  0 0 0 0 #000000;
  }
  .navbar-nav {
    margin: 10px 0;
  }
  #menu-bar {
    padding: 0;
    bottom: auto;
    top: 0;
  }
  #menu-bar > .row {
    margin: 0;
  }
  #menu-bar .logo-menu {
    display: none;
  }
  #menu-bar.fixed {
    position: absolute;
    top: 0;
    bottom: auto;
    left: 0;
  }
  #menu-bar.fixed .logo-menu {
    display: none;
  }
  #menu {
    background: #1b1b1b;
  }
  #menu li {
    float: none;
  }
  #menu li a {
    display: block;
    padding: 10px 15px;
  }
  #menu li a:hover,
  #menu li a:focus,
  #menu li a:active,
  #menu li a.active {
    padding: 10px 15px;
    background: #933956;
  }
  #menu li a:hover:after,
  #menu li a:focus:after,
  #menu li a:active:after,
  #menu li a.active:after {
    opacity: 0;
  }
  .col-menu {
    left: 0;
    margin: 0 -15px;
  }
  .fixed .col-menu {
    left: 0;
  }
  #article-home {
    height: auto;
  }
  #article-home .content-inside {
    padding: 45px 50px 85px 50px;
  }
  #article-home .content-inside .head-class .big {
    font-size: 70px;
    margin: 0;
    text-align: center;
  }
  #article-home .content-inside .head-class .small {
    font-size: 50px;
    margin: 0;
    text-align: center;
  }
  #article-home .bg {
    height: 500px;
  }
  .casting-form-content .head-class {
    text-align: left !important;
  }
  .casting-form-content .head-class.border-right-bottom:after {
    position: absolute;
    left: 0;
    right: auto;
  }
  .city-select {
    margin: 30px 0 0 0;
  }
  #cities-list {
    padding: 30px 0 0 0;
  }
  .casting-form-content .file-input {
    height: auto;
  }
  .news-single-header {
    border-right: none;
    border-bottom: 1px solid #1b1b1b;
    padding: 25px 45px 35px 45px;
    margin: 0 0 25px 0;
  }
  .news-single-text {
    padding: 0;
  }
  #news-home .news-block-photo {
    margin: 0;
  }
  #news-home .news-col {
    width: 100%;
  }
  #news-home .news-col img {
    width: 100%;
  }
  #news-home .news-block .news-block-content {
    z-index: 999;
  }
  .news-block-photo {
    margin: 0 0 30px 0;
  }
  .tab-gallery .tab-gallery-thumbnails {
    padding: 0 30px;
  }
  .tab-gallery .tab-gallery-thumbnails .item {
    height: 200px;
  }
  .tab-gallery .tab-gallery-thumbnails .arrow.prev {
    left: 0;
  }
  .tab-gallery .tab-gallery-thumbnails .arrow.next {
    right: 0;
  }
  .tab-gallery .tab-gallery-big {
    padding: 0 30px;
    height: 500px;
  }
  .tab-gallery .tab-gallery-big .item {
    height: 500px;
  }
  .tab-gallery .arrow.prev {
    left: 0;
  }
  .tab-gallery .arrow.next {
    right: 0;
  }
  #banners-top {
    height: 100%;
  }
  #banners-top > div {
    height: 100%;
  }
  #banners-top > div > div {
    height: 100%;
  }
  #banners-top > div > div > div {
    height: 100%;
  }
  #banners-top .item {
    height: 100%;
    /*background-attachment: initial !important;*/
    background-attachment: local !important;
  }
  #banners-top .arrow.prev {
    margin-top: 0;
  }
  #banners-top .arrow.next {
    margin-top: 0;
  }
  footer#footer .footer-box .inside .ewitryna {
    max-width: 50%;
    margin: 0 auto;
  }
  .social-share {
    position: relative;
    top: auto;
    right: auto;
    margin: 0 0 20px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header#header .search-box {
    margin: 23px 10px 0 80px;
  }
  .logo {
    top: 100px;
  }
  .navbar-toggle {
    background: none;
    float: left;
    margin: 23px 0 0 10px;
  }
  .navbar-toggle .icon-bar {
    background: #ffffff;
  }
  .navbar {
    float: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .navbar-collapse {
    -moz-box-shadow:  0 0 0 0 #000000;
    -webkit-box-shadow:  0 0 0 0 #000000;
    box-shadow:  0 0 0 0 #000000;
  }
  .navbar-nav {
    margin: 10px 0;
  }
  #menu-bar {
    padding: 0;
    bottom: auto;
    top: 0;
    z-index: 300;
  }
  #menu-bar > .row {
    margin: 0;
  }
  #menu-bar .logo-menu {
    display: none;
  }
  #menu-bar.fixed {
    position: absolute;
    top: 0;
    bottom: auto;
    left: 0;
  }
  #menu-bar.fixed .logo-menu {
    display: none;
  }
  #menu {
    background: #1b1b1b;
  }
  #menu li {
    float: none;
  }
  #menu li a {
    display: block;
    padding: 10px 15px;
  }
  #menu li a:hover,
  #menu li a:focus,
  #menu li a:active,
  #menu li a.active {
    padding: 10px 15px;
    background: #933956;
  }
  #menu li a:hover:after,
  #menu li a:focus:after,
  #menu li a:active:after,
  #menu li a.active:after {
    opacity: 0;
  }
  .col-menu {
    left: 0;
    margin: 0 -15px;
  }
  .fixed .col-menu {
    left: 0;
  }
  .logo-menu {
    display: none;
  }
  #article-home {
    height: auto;
  }
  #article-home .content-inside {
    padding: 45px 50px 85px 50px;
  }
  #article-home .content-inside .head-class .big {
    margin: 0;
    text-align: center;
  }
  #article-home .content-inside .head-class .small {
    margin: 0;
    text-align: center;
  }
  #article-home .bg {
    height: 500px;
  }
  .casting-form-content .head-class {
    text-align: left !important;
  }
  .casting-form-content .head-class.border-right-bottom:after {
    position: absolute;
    left: 0;
    right: auto;
  }
  .city-select {
    margin: 30px 0 0 0;
  }
  #cities-list {
    padding: 30px 0 0 0;
  }
  .news-single-header {
    border-right: none;
    border-bottom: 1px solid #1b1b1b;
    padding: 25px 45px 35px 45px;
    margin: 0 0 25px 0;
  }
  .news-single-text {
    padding: 0;
  }
  #banners-top .item {
    background-attachment: initial !important;
  }
  .tab-gallery .tab-gallery-thumbnails {
    padding: 0 30px;
  }
  .tab-gallery .tab-gallery-thumbnails .arrow.prev {
    left: -15px;
  }
  .tab-gallery .tab-gallery-thumbnails .arrow.next {
    right: -15px;
  }
  .tab-gallery .arrow.prev {
    left: -45px;
  }
  .tab-gallery .arrow.next {
    right: -45px;
  }
  #banners-reklama .reklama.container {
    width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  header#header .search-box {
    margin: 23px 10px 0 10px;
  }
  header#header .search-box input {
    max-width: 85px;
  }
  #menu li a {
    padding: 20px 10px;
  }
  #menu li a:hover,
  #menu li a:focus,
  #menu li a:active,
  #menu li a.active {
    padding: 20px 10px;
  }
  #menu-bar .col-social {
    position: absolute;
  }
  #menu-bar.fixed .col-menu {
    width: 100%;
  }
  #menu-bar.fixed .col-social {
    display: none;
  }
  #menu-bar.fixed .logo-menu {
    margin: 33px 20px 0 0;
  }
  #menu-bar.fixed .logo-menu img {
    max-width: 200px;
  }
  #article-home .content-inside {
    padding: 45px 50px 85px 50px;
  }
  #article-home .content-inside .head-class .big {
    font-size: 75px;
    margin: 0 0 0 0;
    text-align: left;
  }
  #article-home .content-inside .head-class .small {
    margin: 10px 0 0 100px;
    text-align: left;
    font-size: 50px;
  }
  #members-home-slider .head-news-class {
    padding: 15px 0 0 15px;
  }
  #members-home-slider .item .hover-bg .number {
    bottom: 30px;
    font-size: 100px;
  }
  #banners-bottom-home .item {
    background-position: center top !Important;
  }
  footer#footer .footer-box .inside .ewitryna {
    max-width: 50%;
    margin: 0 auto;
  }
  #news-home.subpage .container {
    padding: 0;
    width: auto;
  }
  #banners-top .item {
    background-attachment: local !important;
  }
  #banners-top.subpage .item {
    /*background-position: left -245px top !important;*/
  }
  .tab-gallery .tab-gallery-thumbnails {
    padding: 0 30px;
  }
  .tab-gallery .tab-gallery-thumbnails .arrow.prev {
    left: -15px;
  }
  .tab-gallery .tab-gallery-thumbnails .arrow.next {
    right: -15px;
  }
  #banners-reklama .reklama.container {
    width: 100%;
  }
}
@media (min-width: 1200px) and (max-width: 1450px) {
  header#header .search-box {
    margin: 23px 10px 0 10px;
  }
  header#header .search-box input {
    max-width: 65px;
  }
  #menu li a {
    padding: 20px 10px;
  }
  #menu li a:hover,
  #menu li a:focus,
  #menu li a:active,
  #menu li a.active {
    padding: 20px 10px;
  }
  #article-home .content-inside {
    padding: 45px 50px 85px 50px;
  }
  #article-home .content-inside .head-class .big {
    font-size: 75px;
    margin: 0 0 0 40px;
    text-align: left;
  }
  #article-home .content-inside .head-class .small {
    margin: 10px 0 0 140px;
    text-align: left;
    font-size: 50px;
  }
  .tab-gallery .tab-gallery-thumbnails {
    padding: 0 30px;
  }
  .tab-gallery .tab-gallery-thumbnails .arrow.prev {
    left: -15px;
  }
  .tab-gallery .tab-gallery-thumbnails .arrow.next {
    right: -15px;
  }
}
@media (min-width: 1451px) {
  #banners-reklama .reklama.container {
    width: 1630px;
  }
}
