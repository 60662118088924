// main file: ../style.less

// SM Small devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991px) {

    // =header
    //========================================================================*/
    header#header {
        .search-box {
            margin: 23px 10px 0 80px;
        }
    }
    .logo {
        top: 100px;
    }

    // =menu
    //========================================================================*/
    .navbar-toggle {
        background: none;
        float: left;
        margin: 23px 0 0 10px;

        .icon-bar {
            background: @text_on_main_color;
        }
    }
    .navbar {
        float: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    .navbar-collapse {
        .box-shadow(~'', 0, 0, 0, 0, #000000);
    }
    .navbar-nav {
        margin: 10px 0;
    }
    #menu-bar {
        padding: 0;
        bottom: auto;
        top: 0;
        z-index: 300;

        >.row {
            margin: 0;
        }
        .logo-menu {
            display: none;
        }
        &.fixed {
            position: absolute;
            top: 0;
            bottom: auto;
            left: 0;

            .logo-menu {
                display: none;
            }
        }
    }
    #menu {
        background: @main_color;

        li {
            float: none;

            a {
                display: block;
                padding: 10px 15px;

                &:hover, &:focus, &:active, &.active {
                    padding: 10px 15px;
                    background: @main_color2;

                    &:after {
                        opacity: 0;
                    }
                }
            }
        }
    }
    .col-menu {
        left: 0;
        margin: 0 -15px;
    }
    .fixed .col-menu {
        left: 0;
    }
    .logo-menu {
        display: none;
    }

    // =article-home
    //========================================================================*/
    @article_home_height_sm: auto;

    #article-home {
        height: @article_home_height_sm;

        .content-inside {
            padding: 45px 50px 85px 50px;

            .head-class{
                .big {
                    margin: 0;
                    text-align: center;
                }
                .small {
                    margin: 0;
                    text-align: center;
                }
            }
        }
        .bg {
            height: 500px;
        }
    }

    // =casting
    //========================================================================*/
    .casting-form-content {
        .head-class {
            text-align: left !important;

            &.border-right-bottom:after {
                position: absolute;
                left: 0;
                right: auto;
            }
        }
    }
    .city-select {
        margin: 30px 0 0 0;
    }
    #cities-list {
        padding: 30px 0 0 0;
    }

    // =news
    //========================================================================*/
    .news-single-header {
        border-right: none;
        border-bottom: 1px solid #1b1b1b;
        padding: 25px 45px 35px 45px;
        margin: 0 0 25px 0;
    }
    .news-single-text {
        padding: 0;
    }

    // =banners-top
    //========================================================================*/
    #banners-top {
        .item {
            background-attachment: initial !important;
        }
    }

    // =tab-gallery
    //========================================================================*/
    .tab-gallery {
        .tab-gallery-thumbnails {
            padding: 0 30px;

            .arrow {
                &.prev {
                    left: -15px;
                }
                &.next {
                    right: -15px;
                }
            }
        }

        .arrow {
            &.prev {
                left: -45px;
            }
            &.next {
                right: -45px;
            }
        }
    }

    #banners-reklama {
        .reklama {
            &.container {
                width:100%; 
            }
        }
    }
}