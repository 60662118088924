// main file: ../style.less

// XS Extra small devices (phones, less than 768px)
@media (max-width: 767px) {

    // =blocks
    //========================================================================*/
    .block {
        .block-float-left {
            float: none;
            padding: 0 0 @padding_col 0;
        }
        .block-float-right {
            float: none;
            padding: 0 0 @padding_col 0;
        }
        .link-file-mini, .link-file-medium, .link-file-big {
            span {
                margin: 0 0 15px 0;
            }
        }
        &.block-type-4 {
            img {
                margin: 15px auto 0 auto;
            }
        }
        img {
            margin: 0 auto 15px auto;
        }

        &.block-multiple-column-1 {
            .multiple-column(1);
        }
        &.block-multiple-column-2 {
            .multiple-column(1);
        }
        &.block-multiple-column-3 {
            .multiple-column(1);
        }
    }

    // =main
    //========================================================================*/
    .content-contact {
        margin: 80px 0 80px 0;
    }

    // =helpers
    //========================================================================*/
    .back-link {
        right: 15px;
    }
    .row-big {
        margin-left: -15px;
        margin-right: -15px;

        > div {
            padding: 0 15px;
        }
    }
    .container {
        &.big {
            padding: 0 15px;
        }
    }

    // =header
    //========================================================================*/
    header#header {
        .search-box {
            margin: 0;
            position: absolute;
            top: 24px;
            left: 60px;

            input {
                height: 32px;
            }
        }
        .social {
            position: absolute;
            top: 0;
            right: 15px;
            font-size: 25px;

            input {
                max-width: 100px;
            }
        }
    }
    .logo {
        top: 100px;

        img {
            max-width: 200px;
        }
    }

    // =menu
    //========================================================================*/
    .navbar-toggle {
        background: none;
        float: left;
        margin: 23px 0 0 10px;

        .icon-bar {
            background: @text_on_main_color;
        }
    }
    .navbar {
        float: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    .navbar-collapse {
        .box-shadow(~'', 0, 0, 0, 0, #000000);
    }
    .navbar-nav {
        margin: 10px 0;
    }
    #menu-bar {
        padding: 0;
        bottom: auto;
        top: 0;

        >.row {
            margin: 0;
        }
        .logo-menu {
            display: none;
        }

        &.fixed {
            position: absolute;
            top: 0;
            bottom: auto;
            left: 0;

            .logo-menu {
                display: none;
            }
        }
    }
    #menu {
        background: @main_color;

        li {
            float: none;

            a {
                display: block;
                padding: 10px 15px;

                &:hover, &:focus, &:active, &.active {
                    padding: 10px 15px;
                    background: @main_color2;

                    &:after {
                        opacity: 0;
                    }
                }
            }
        }
    }
    .col-menu {
        left: 0;
        margin: 0 -15px;
    }
    .fixed .col-menu {
        left: 0;
    }

    // =article-home
    //========================================================================*/
    @article_home_height_sm: auto;

    #article-home {
        height: @article_home_height_sm;

        .content-inside {
            padding: 45px 50px 85px 50px;

            .head-class{
                .big {
                    font-size: 70px;
                    margin: 0;
                    text-align: center;
                }
                .small {
                    font-size: 50px;
                    margin: 0;
                    text-align: center;
                }
            }
        }
        .bg {
            height: 500px;
        }
    }

    // =casting
    //========================================================================*/
    .casting-form-content {
        .head-class {
            text-align: left !important;

            &.border-right-bottom:after {
                position: absolute;
                left: 0;
                right: auto;
            }
        }
    }
    .city-select {
        margin: 30px 0 0 0;
    }
    #cities-list {
        padding: 30px 0 0 0;
    }
    .casting-form-content {
        .file-input {
            height: auto;
        }
    }

    // =news
    //========================================================================*/
    .news-single-header {
        border-right: none;
        border-bottom: 1px solid #1b1b1b;
        padding: 25px 45px 35px 45px;
        margin: 0 0 25px 0;
    }
    .news-single-text {
        padding: 0;
    }
    #news-home {
        .news-block-photo {
            margin: 0;
        }
        .news-col {
            width: 100%;

            img {
                width: 100%;
            }
        }
        .news-block {
            .news-block-content {
                z-index: 999;
            }
        }
    } 
    .news-block-photo {
        margin: 0 0 30px 0;
    }

    // =tab-gallery
    //========================================================================*/
    .tab-gallery {
        .tab-gallery-thumbnails {
            padding: 0 30px;

            .item {
                height: 200px;
            }
            .arrow {
                &.prev {
                    left: 0;
                }
                &.next {
                    right: 0;
                }
            }
        }

        .tab-gallery-big {
            padding: 0 30px;
            height: 500px;

            .item {
                height: 500px;
            }
        }
        .arrow {
            &.prev {
                left: 0;
            }
            &.next {
                right: 0;
            }
        }
    }

    // =banners-top
    //========================================================================*/
    @banners_top_height_xs: 100%;

    #banners-top {
        height: @banners_top_height_xs;

        > div {
            height: @banners_top_height_xs;
            > div {
                height: @banners_top_height_xs;
                > div {
                    height: @banners_top_height_xs;
                }
            }
        }
        .item {
            height: @banners_top_height_xs;
            /*background-attachment: initial !important;*/
            background-attachment: local !important;
        }

        .arrow {
            &.prev {
                margin-top: 0;
            }
            &.next {
                margin-top: 0;
            }
        }
    }

    // =footer
    //========================================================================*/
    footer#footer {
        .footer-box {
            .inside {
                .ewitryna {
                    max-width: 50%;
                    margin: 0 auto;
                }
            }
        }
    }

    // =social
    //========================================================================*/
    .social-share {
        position: relative;
        top: auto;
        right: auto;
        margin: 0 0 20px 0;
    }

}