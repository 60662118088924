// main file: ../style.less

// =mixins
//============================================================================*/
.center-absolute(@top: 50%, @left: 50%) {
    position: absolute;
    top: @top;
    left: @left;
    transform: translate(-@left, -@top);
    -moz-transform: translate(-@left, -@top);
    -webkit-transform: translate(-@left, -@top);
}

.no-center-absolute() {
    position: relative;
    top: auto;
    left: auto;
    transform: translate(none, none);
    -moz-transform: translate(none, none);
    -webkit-transform: translate(none, none);
}

.border-radius(@size: 50%) {
    -webkit-border-radius: @size;
    -moz-border-radius: @size;
    border-radius: @size;
}

.transition(@time: 0.5s, @item: All, @effect: ease) {
    transition: @item @time @effect;
    -webkit-transition: @item @time @effect;
    -moz-transition: @item @time @effect;
    -o-transition: @item @time @effect;
}

.no-transition() {
    transition: none;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
}

.placeholder(@font_weight: normal, @color: #d3d3d3, @font_size: 14px) {
    *::-webkit-input-placeholder {
        color: @color !important;
        font-weight: @font_weight;
        font-size: @font_size;
    }
    *:-moz-placeholder {
        color: @color !important;
        font-weight: @font_weight;
        font-size: @font_size;
    }
    *::-moz-placeholder {
        color: @color !important;
        font-weight: @font_weight;
        font-size: @font_size;
    }
    *:-ms-input-placeholder {
        color: @color !important;
        font-weight: @font_weight;
        font-size: @font_size;
    }
}

.box-shadow(@inset: ~'', @distance_x: 0, @distance_y: 0, @blur: 20px, @spread: 0, @color: #000000) {
    -moz-box-shadow: @inset @distance_x @distance_y @blur @spread @color;
    -webkit-box-shadow: @inset @distance_x @distance_y @blur @spread @color;
    box-shadow: @inset @distance_x @distance_y @blur @spread @color;
}

.multiple-column(@col: 1) {
    -webkit-column-count: @col;
    -moz-column-count: @col;
    column-count: @col;
}

.suqre() {
    height: 0;
    padding-bottom: 100%;
}

.display-flex() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.filter(@filterString: ~"grayscale(1)") {
    -webkit-filter: @filterString;
    -moz-filter: @filterString;
    -o-filter: @filterString;
    -ms-filter: @filterString;
    filter: @filterString;
}