// main file: ../style.less

// =menu
//============================================================================*/
@menu_bar_bg: rgba(0, 0, 0, 0.77);
@menu_font_family: @header_font_family;
@menu_font_size: 16px;
@menu_font_weight: 300;
@menu_color: @text_on_main_color;
@menu_link_bg: none;
@menu_hover_color: #ffffff;
@menu_hover_bg: none;
@menu_dropdown_bg: #ffffff;
@menu_dropdown_color: #393939;
@menu_dropdown_hover_color: #ffffff;
@menu_dropdown_hover_bg: #2480D5;

#menu-bar {
    background: @menu_bar_bg;
    height: 80px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: @container_padding;
    padding: 0 30px 0 80px;
    z-index: 9999;

    .logo-menu {
        display: block;
        float: left;
        position: relative;
        margin: 20px 20px 0 0;
    }
    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;

        .logo-menu {
            display: block;
        }
    }
}
#menu {
    list-style: none;
    padding: 0;

    li {
        float: left;

        a {
            padding: 20px 20px;
            font-family: @menu_font_family;
            font-size: @menu_font_size;
            font-weight: @menu_font_weight;
            color: @menu_color;
            background: @menu_link_bg;
            line-height: 40px;
            text-transform: uppercase;

            &:after {
                .transition();
                display: block;
                margin: 0 auto;
                content: "";
                width: 70%;
                height: 3px;
                background: @text_on_main_color;
                opacity: 0;
            }
            &:hover, &:focus, &:active, &.active {
                color: @menu_hover_color;
                background: @menu_hover_bg;
                padding: 20px 20px;

                &:after {
                    opacity: 1;
                }
            }
        }

        .dropdown-menu {
            li {
                float: none;

                a {
                    background: @menu_dropdown_bg;
                    color: @menu_dropdown_color;

                    &:hover, &:focus, &:active, &.active {
                        color: @menu_dropdown_hover_color;
                        background: @menu_dropdown_hover_bg;
                    }
                }
            }
        }
    }
}
.navbar {
    float: left;
    margin: 0;
    padding: 0;
    border: none;
}
.navbar-collapse {
    padding: 0;
}
.col-menu {
    position: relative;
    //left: -370px;
    left: -185px;
    .transition();
}
.fixed {
    .col-menu {
        left: -50px;
    }
}

// =submenu
//============================================================================*/
@submenu_bg: @main_color;
@submenu_color: #ffffff;

.submenu-box {
    background: @submenu_bg;
    padding: 20px;
}
#submenu {
    padding: 0;
    list-style: none;

    li {
        a {
            display: block;
            padding: 5px 15px;
            color: @submenu_color;

            &:hover, &:focus, &:active, &.active {
                background: darken(@submenu_bg, 20%);
            }
        }

        .inside-submenu {
            padding: 0;
            list-style: none;

            li {
                a {
                    padding-left: 40px;

                    &:hover, &:focus, &:active, &.active {
                        background: none;
                        color: darken(@submenu_bg, 20%);
                    }
                }
            }
        }
    }
}