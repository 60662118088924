// main file: ../style.less

// =heads
//============================================================================*/
.head_class() {
    font-family: @header_font_family;
    font-size: 24px;
    font-weight: 300;
    margin: 0 0 30px 0;
    position: relative;

    a {
        color: @main_text_color;
    }
    &.semi-medium {
        font-size: 30px;
        line-height: 35px;
        font-weight: 400;
        text-transform: uppercase;
    }
    &.big {
        text-transform: uppercase;
        font-size: 60px;
        line-height: 65px;
        font-weight: 300;
        margin: 0 0 60px 0;
        color: #1c1c1d;
        text-align: center;

        &:after {
            display: block;
            margin: 30px auto 0 auto;
            content: "";
            width: 37px;
            height: 3px;
            background: @main_color2;
        }
    }
    &.medium {
        font-size: 30px;
        font-weight: 300;
        text-transform: uppercase;
        margin: 0 0 40px 0;
        letter-spacing: 4px;

        &:after {
            display: block;
            margin: 20px 0 0 0;
            content: "";
            width: 37px;
            height: 3px;
            background: @main_color2;
        }
    }
    &.border-right-bottom {
        &:after {
            position: absolute;
            right: 5px;
        }
    }
    &.no-upper {
        text-transform: none;
    }
    &.no-border {
        &:after {
            display: none;
        }
    }
    &.no-margin {
        margin: 0;
    }
}
.head-class {
    .head_class();
}
.head-news-class {
    .head_class();
    color: @main_text_color;
    font-size: 24px;
    font-weight: 300;
}

// =buttons
//============================================================================*/
.main_button() {
    font-family: @header_font_family;
    color: @main_color2;
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    background: none;
    border: none;
    display: inline-block;
    padding: 20px 15px 10px 15px;
    border-top: 3px solid @main_color2;

    &:hover, &:focus, &:active {
        color: darken(@main_color2, 20%);
        border-color: darken(@main_color2, 20%);
    }
}
.button {
    .main_button();
}
.btn {
    outline: none;
    .border-radius(0);
}
.btn-primary {
    background: @main_color2;
    border: 1px solid darken(@main_color2, 10%);

    &:hover, &:focus, &:active {
        background: darken(@main_color2, 20%);
    }
}

// =breadcrumbs
//============================================================================*/
#breadcrumbs {
    line-height: 31px;
    list-style: none;
    padding: 0;

    li {
        float: left;
        margin: 0 10px 0 0;

        a {
            color: @link_color;

            &:hover, &:focus, &:active {
                text-decoration: underline;
            }
        }

        &:before {
            content: '›';
            margin: 0 10px 0 0;
        }
        &:first-child:before {
            display: none;
        }
    }
}

// =map
//============================================================================*/
.svg-map {
    max-width: 100%;
}
.cls-1{
    fill: #ffffff;
    cursor: pointer;

    &:hover, &.active {
        fill: @main_color2;
    }
}
.cls-2{
    fill: @main_color2;
}

// =modal
//============================================================================*/
.modal-content {
    .border-radius(0);
}

// =contact-map
//============================================================================*/
#contact-map {
    height: 300px;
    width: 100%;

    .block {
        margin: 0;
    }
}

// =social
//============================================================================*/
.news-single-top {
    position: relative;
}
.social-share {
    position: absolute;
    top: -30px;
    right: 0;

    div.item {
        display: block;
        float: left;
        margin: 0 10px 0 0;

        &.fb-item {
            margin-top: -10px;
        }
        .email-share {
            position: relative;
            background: #A1A1A1;
            .border-radius(2px);
            font-size: 13px;
            padding: 3px 8px;
            color: #ffffff;
            top: -5px;
            .no-transition();

            i {
                margin: 0 3px 0 0;
            }
            &:hover {
                background: darken(#A1A1A1, 20%);
            }
        }
    }
}

.slick-slider .slick-track, .slick-slider .slick-list {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
}