// main file: ../style.less

// =partners
//============================================================================*/
.partners-list {
    .partners-row {
        >div:last-child {
            .partners-box {
                border-right: @main_border;
            }
        }
        &:first-child {
            .partners-box {
                border-top: @main_border;
            }
        }
    }

    .partners-box {
        height: 320px;
        border-bottom: @main_border;
        border-left: @main_border;
        overflow: hidden;

        .inside {
            .center-absolute();

            img {
                transform: scale(1);
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -o-transform: scale(1);
                -ms-transform: scale(1);
                .transition();
                max-width: 60%;
                margin: 0 auto;
            }

            .head-class {
                margin: 0;

                &:after {
                    display: block;
                    margin: 20px auto 0 auto;
                    content: "";
                    width: 37px;
                    height: 3px;
                    background: @text_on_main_color;
                }
            }
        }
        &.head {
            background: @main_color2;
            border-color: @main_color2;
            color: @text_on_main_color;
        }
        &:hover {
            .inside {
                img {
                .transition();
                    transform: scale(1.4);
                    -webkit-transform: scale(1.4);
                    -moz-transform: scale(1.4);
                    -o-transform: scale(1.4);
                    -ms-transform: scale(1.4);
                }
            }
        }
    }
}