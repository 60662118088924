// main file: ../style.less

// MD Medium devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1200px) {

    // =header
    //========================================================================*/
    header#header {
        .search-box {
            margin: 23px 10px 0 10px;

            input {
                max-width: 85px;
            }
        }
    }

    // =menu
    //========================================================================*/
    #menu {
        li {
            a {
                padding: 20px 10px;

                &:hover, &:focus, &:active, &.active {
                    padding: 20px 10px;
                }
            }
        }
    }
    #menu-bar {
        .col-social {
            position: absolute;
        }
        &.fixed {
            .col-menu {
                width: 100%;
            }
            .col-social {
                display: none;
            }

            .logo-menu {
                margin: 33px 20px 0 0;

                img {
                    max-width: 200px;
                }
            }
        }
    }

    // =article-home
    //========================================================================*/
    #article-home {
        .content-inside {
            padding: 45px 50px 85px 50px;

            .head-class {
                .big {
                    font-size: 75px;
                    margin: 0 0 0 0;
                    text-align: left;
                }
                .small {
                    margin: 10px 0 0 100px;
                    text-align: left;
                    font-size: 50px;
                }
            }
        }
    }

    // =members-home
    //========================================================================*/
    #members-home-slider {
        .head-news-class {
            padding: 15px 0 0 15px;
        }
        .item {
            .hover-bg {
                .number {
                    bottom: 30px;
                    font-size: 100px;
                }
            }
        }
    }

    // =banners-bottom-home
    //========================================================================*/
    #banners-bottom-home {
        .item {
            background-position: center top !Important;
        }
    }

    // =footer
    //========================================================================*/
    footer#footer {
        .footer-box {
            .inside {
                .ewitryna {
                    max-width: 50%;
                    margin: 0 auto;
                }
            }
        }
    }

    // =news-home
    //========================================================================*/
    #news-home {
        &.subpage {
            .container {
                padding: 0;
                width: auto;
            }
        }
    }

    // =banners-top
    //========================================================================*/
    #banners-top {
        .item {
            background-attachment: local !important;
        }
        &.subpage {
            .item {
                /*background-position: left -245px top !important;*/
            }
        }
    }

    // =tab-gallery
    //========================================================================*/
    .tab-gallery {
        .tab-gallery-thumbnails {
            padding: 0 30px;

            .arrow {
                &.prev {
                    left: -15px;
                }
                &.next {
                    right: -15px;
                }
            }
        }
    }

    
    #banners-reklama {
        .reklama {
            &.container {
                width:100%; 
            }
        }
    }
}