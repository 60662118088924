// main file: ../style.less

// =forms
//============================================================================*/
@input_font_size: 13px;
@input_font_weight: normal;
@input_color: #000000;
@input_bg: #ebebeb;
@input_border: 1px solid #ebebeb;
@input_padding: 4px 10px;
@input_border_focus_color: #f9c57a;

.form-control {
    border: @main_border;
    box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.06);
    height: 48px;
    .border-radius(0);

    &:focus {
        border: 1px solid @main_color2;
        .box-shadow(~'', 0, 3px, 0, 0, lighten(@main_color2, 20%));
    }
}
textarea {
    &.form-control {
        padding: 12px;
    }
}
.contact-form-box {
    textarea {
        height: 254px;
    }
    .button-box {
        margin: 40px 0 0 0;
    }
}
input[type=checkbox], input[type=radio] {
    margin: 7px 0 0;
    margin-top: 1px;
    line-height: normal;
}

label {
    font-weight: 300;
    margin: 0 0 10px 0;
}
.checkbox label, .radio label {
    font-weight: 300;
    padding-left: 30px;
}
.input-group-addon {
    background: none;
    box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.06);
    .border-radius(0);
}
.input-group {
    &.small {
        max-width: 170px;
    }
}

// =select
//============================================================================*/
.styled-select {
    position: relative;

    select {
        outline: none;
        -webkit-appearance:none;
        -moz-appearance:none;
        -ms-appearance:none;
        -o-appearance:none;
        width: 100%;
        height: 48px;
        position: relative;
        z-index: 10;
        cursor: pointer;

    }
    i {
        position: absolute;
        right: 20px;
        top: 16px;
        color: #646464;
    }
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 50px;
        height: 100%;
        background: @main_border_color;
        height: 48px;
        width: 1px;
    }
}
.styled-select select::-ms-expand {
    display: none;
}

@media screen and (min-width:0) {
    .styled-select select {
        background :none;
    }
}

// =casting-form
//============================================================================*/
.casting-form-content {
    margin: 0 0 110px 0;

    .head-class {
        letter-spacing: 4px;
    }
    textarea {
        height: 135px;
    }

    .file-input{
        display: block;
        border: 1px solid #b2b2b2;
        background: #eeeeee;
        box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.06);
        height: 110px;
        font-size: 20px;
        font-weight: 300;
        line-height: 28px;
        text-align: center;
        padding: 25px 0;
        cursor: pointer;
        margin: 0 0 70px 0;
        .transition();

        .small {
            font-size: 16px;
        }
        &:hover, &:active, &:focus {
            background: darken(#eeeeee, 20%);
        }
    }
}
.dropzone .dz-preview .dz-error-message, .dropzone-previews .dz-preview .dz-error-message {
    left: 0 !important;
}

.check {
    position: absolute;
    margin: 4px 0 0 0px;

    label {
        display: block;
        border: @main_border;
        background: none;
        width: 17px;
        height: 17px;
        min-height: 17px;
        padding: 0;

        i {
            display: block;
            text-align: center;
            opacity: 0;
            color: @main_color;
            font-size: 18px;
            line-height: 13px;
        }
    }

    input {
        opacity: 0;

        &:checked + label {
            i {
                opacity: 1;
            }
        }
    }
}