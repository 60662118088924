// main file: ../style.less

// =helpers
//============================================================================*/
.back-link {
    display: inline-block;
    margin: 0 0 20px 0;
    position: absolute;
    top: 0;
    right: 0;
}
.link-absolute {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.padding0 {
    padding: 0;
}
.colored {
    color: @main_color2;
}
.slick-disabled {
    color: #dedede;
}
.row-big {
    margin-left: -40px;
    margin-right: -40px;
    margin-bottom: 40px;

    > div {
        padding: 0 40px;
    }
}
.alert {
    margin-bottom: 50px;
}
.display-block {
    display: block;
}
.container {
    &.big {
        padding: 0 40px;
    }
}
.hover-bg {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .transition();
    background: rgba(46, 46, 46, 0.5);
    background: -moz-linear-gradient(top, rgba(46, 46, 46, 0.5) 0%, rgba(0, 0, 0, 5) 100%);
    background: -webkit-linear-gradient(top, rgba(46, 46, 46, 0.5) 0%, rgba(0, 0, 0, 5) 100%);
    background: linear-gradient(to bottom, rgba(46, 46, 46, 0.5) 0%, rgba(0, 0, 0, 5) 100%);
    z-index: 15;
}