// main file: ../style.less

@margin_block: 0 0 30px 0;
@padding_col: 15px; // Bootstrap padding col (zmienić jeżeli zmieniliśmy oryginalnego bootstrapa)
.head_block() {
    color: #474747;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin: 0 0 30px 0;
}
@tab_bg: #ffffff;
@tab_color: #000000;
@tab_active_bg: #2480d5;
@tab_active_color: #ffffff;
@tab_active_border: #2480d5;

// =block
//============================================================================*/
.block {
    margin: @margin_block;
    position: relative;
    clear: both;

    figcaption {
        margin: 20px 0 0 0;
        text-align: center;
    }
    .block-float-left {
        float: left;
        padding: 0 (2 * @padding_col) @padding_col 0;
    }
    .block-float-right {
        float: right;
        padding: 0 0 @padding_col (2 * @padding_col);
    }
    .ico-file {
        margin: 0 15px 0 0;
        position: absolute;
        top: 0;
        left: @padding_col;
    }
    .link-file-mini {
        span {
            min-height: 30px;
            display: block;
            padding: 4px 0 0 40px;
        }
    }
    .link-file-medium {
        span {
            min-height: 50px;
            display: block;
            padding: 12px 0 0 70px;
        }
    }
    .link-file-big {
        span {
            min-height: 73px;
            display: block;
            padding: 23px 0 0 90px;
        }
    }
    .head-block {
        .head_block();
    }
    .block-slide-content {
        display: none;
        margin: 20px 0 0 0;

        .block {
            &:last-child {
                margin: 0;
            }
        }
    }

    .block-slide-header {
        cursor: pointer;
        padding: 10px 15px;
        background: @tab_active_bg;
        color: @tab_active_color;

        i {
            float: right;
            margin: 5px 0 0 0;
        }
    }
    .block-slide-tab {
        margin: @margin_block;
    }
    .block-tabs-id {
        background: @tab_bg;
        color: @tab_color;

        .resp-arrow {
            border-top: 12px solid @tab_color;
        }
        &.resp-tab-active, .resp-tab-active {
            background: @tab_active_bg !important;
            color: @tab_active_color !important;
            border-top: 1px solid @tab_active_border !important;
            border-color: @tab_active_border !important;

            .resp-arrow {
                border-top: none;
                border-bottom: 12px solid @tab_active_color;
            }
        }
    }
    .resp-tab-content {
        border-color: @tab_active_border;

        .block {
            &:last-child {
                margin: 0;
            }
        }
    }
    &.block-multiple-column-1 {
        .multiple-column(1);
    }
    &.block-multiple-column-2 {
        .multiple-column(2);
    }
    &.block-multiple-column-3 {
        .multiple-column(3);
    }
} 
